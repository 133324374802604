var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-link',{staticClass:"article",attrs:{"type":false,"to":"article","params":{alias: _vm.article.alias}}},[_c('div',{staticClass:"article-img-wrapper"},[(_vm.article.thumbnail)?_c('cms-image',{staticClass:"article-image",attrs:{"lazy":"","src":_vm.article.thumbnail,"base":{width: 314, height: 119},"xl":{
				width: 220,
				height: {
					lg: 440,
					sm: 203
				}[_vm.imageSize]
			}}}):_vm._e()],1),_c('div',{staticClass:"article-preview-data"},[_c('base-font',{attrs:{"color":"primary","variant":"small"}},[_vm._v(_vm._s(_vm._f("date")(_vm.article.datePublished)))]),_c('base-font',{staticClass:"article-title",attrs:{"weight":"bold","variant":"small-header","tag":_vm.homepage ? 'h3' : 'h2'}},[_vm._v(_vm._s(_vm.article.title))]),_c('div',{staticClass:"article-post-preview",class:{'small-article': _vm.imageSize === 'sm'},domProps:{"innerHTML":_vm._s(_vm.article.introduction)}}),_c('base-button',{staticClass:"article-btn",attrs:{"type":"white"}},[_vm._v(" "+_vm._s(_vm.langs.blog_button_text)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }