<template>
	<div class="section-wrapper">
		<base-container :fluid="value.fluid">
			<base-row :reverse="value.reverse">
				<base-col col="12" >
					<cms-text :value="`${prefix}.title`" :props="{variant: 'section-header', tag: value.header_tag }"/>
				</base-col>
				<div class="articles-wrapper">
					<skeleton-loader class=" article" v-for="(article, i) in articles" :key="i"  :isVisible="loading">
						<base-article
							:article="article"
							:prefix="prefix"
							:imageSize="i === 0 ? 'lg' : 'sm'"
						/>
					</skeleton-loader>
				</div>
			</base-row>
		</base-container>
	</div>
</template>

<script>
import SkeletonLoader from '~/website/front/components/molecules/loaders/SkeletonLoader'
import BaseArticle from '../../components/BaseArticle'
import { isPageFromNull } from 'utils/ssrCheck'
export default {
	async prefetch () {
		if (process.server) {
			await this.fetchArticles()

			this.$app.page.componentsData.newest_blog_posts = {}
			this.$app.page.componentsData.newest_blog_posts.articles = this.articles
		}
	},
	data () {
		return {
			articles: Array(3).fill({}),
			loading: true
		}
	},
	components: {
		BaseArticle,
		SkeletonLoader
	},
	props: {
		prefix: String,
		value: Object
	},
	methods: {
		async fetchArticles () {
			const data = await this.$app.getService('rext').getArticles(3)
			if (data.error) return this.articles
			this.articles = data
			this.timeout()
		},
		recreateDataFromComponentsData () {
			this.articles = this.$app.page.componentsData.newest_blog_posts.articles
			this.timeout()
		},
		timeout () {
			setTimeout(() => {
				this.loading = false
			}, 500)
		}
	},
	mounted () {
		if (isPageFromNull(this.$app)) {
			this.recreateDataFromComponentsData()
		} else {
			this.fetchArticles()
		}
	}
}
</script>
<style lang="scss" scoped>
.article {
	width: 100%;
	min-height: 214px;
	& :deep(.article) {
		height: 100%;
		@include media-breakpoint-landscape() {
			max-height: none;
		}
	}
	@include media-breakpoint-up(xl) {
		& :deep(.article) {
			max-height: none;
		}
	}
}
.articles-wrapper {
	width: 100%;
	padding: 0 1.5rem;
	margin-bottom: 4rem;
	@include media-breakpoint-up(md) {
		display: flex;
	}
	@include media-breakpoint-up(xl) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 214px);
		grid-gap: 30px;
		margin-bottom: 8rem;
	}
}
.article {
	&:nth-child(3) {
		display: none;
	}
	@include media-breakpoint-up(md) {
		margin-right: 1rem;
		&:nth-child(2) {
			margin-right: 0;
		}
	}
	@include media-breakpoint-up(lg) {
		margin-right: .4rem;
		&:nth-child(2) {
			margin-right: .4rem;
		}
		&:nth-child(3) {
			margin-right: 0;
			display: block;
		}
	}
	@include media-breakpoint-up(xl) {
		&:first-child {
			grid-area: 1/1/3/3;
		}
		&:nth-child(2) {
			grid-area: 1/3/2/5;
		}
		&:nth-child(3) {
			display: flex;
			grid-area: 2/3/3/5;
		}
	}
}
</style>
