<template>
	<base-link :type="false" class="article" to="article" :params="{alias: article.alias}">
		<div class="article-img-wrapper">
			<cms-image
				v-if="article.thumbnail"
				class="article-image"
				lazy
				:src="article.thumbnail"
				:base="{width: 314, height: 119}"
				:xl="{
					width: 220,
					height: {
						lg: 440,
						sm: 203
					}[imageSize]
				}"
			/>

		</div>
		<div class="article-preview-data">
			<base-font color="primary" variant="small">{{article.datePublished | date}}</base-font>
			<base-font class="article-title" weight="bold" variant="small-header" :tag="homepage ? 'h3' : 'h2'">{{article.title}}</base-font>

			<div class="article-post-preview" :class="{'small-article': imageSize === 'sm'}" v-html="article.introduction"></div>
			<base-button class="article-btn" type="white">
				{{langs.blog_button_text}}
			</base-button>
		</div>
	</base-link>
</template>
<script>

export default {
	props: {
		article: [Object, Number],
		prefix: String,
		imageSize: {
			type: String,
			default: 'sm'
		}
	},
	computed: {
		langs () {
			return this.$app.translator.get('articles')
		},
		homepage () {
			const routeAlias = this.$app.page.route.meta.alias
			return routeAlias === 'homepage'
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('articles')
	}
}
</script>
<style lang="scss" scoped>
.safari {
	.article {
		max-height: 420px;
		@include media-breakpoint-up(xl) {
			max-height: none;
		}
	}
}
.article {
	display: flex;
	flex-direction: column;
	background-color: $white;
	width: 100%;
	margin-bottom: 3rem;
	text-decoration: none;
	box-shadow: $base-shadow;

	@include media-breakpoint-up(lg) {
		box-shadow: none;
		border: 1px solid $gray-500;
	}
	@include media-breakpoint-up(xl) {
		box-shadow: $base-shadow;
		border: none;
	}

	&-img-wrapper {
		padding: 1rem;
	}
	&-image {
		:deep(img) {
			width: 100%;
			@include media-breakpoint-up(xl) {
				width: 190px;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&-preview-data {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-grow: 1;
		padding: 1.5rem 1.5rem .1rem;

		@include media-breakpoint-up(xl) {
			padding-bottom: 2.5rem;
		}
	}
	&-btn {
		justify-content: center;
		margin-bottom: 2rem;
		margin-top: auto;
		font-size: 1.5rem;
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			padding: 2rem;
		}
		@include media-breakpoint-up(lg) {
			font-size: 1.6rem;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 0;

		}
	}
	&-post-preview {
		margin-top: 3rem;
		margin-bottom: 3rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		color: $primary;
		-webkit-line-clamp: 3;
		&.small-article{
			-webkit-line-clamp: 1;
		}
		@include media-breakpoint-up(xl) {
			margin-top: 1rem;
			margin-bottom: 0;
			-webkit-line-clamp: 7;
		}
	}
	:deep(.article-title ) {
		@include media-breakpoint-up(xl) {
			margin-top: 0;
		}
	}
	@include media-breakpoint-up(xl) {
		flex-direction: row;
		margin-bottom: 0;
	}
}
</style>
